import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Content from "../components/news/content";

const rootElement = document.getElementById("content_news");
const root = createRoot(rootElement);

//pagination configuration
let ITEMS_FOR_PAGES = 6
let datosFromApi = []
let items = []
let currentPage = 0
let pages = 0

export default class News extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'news',
            info:[],
            text:[],
            list:[],
            error: null,
        }

        this.nextHandler = this.nextHandler.bind(this)
        this.prevHandler = this.prevHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/news')
            let data = await res.json()

            datosFromApi = data[2]
            pages = data[2].length / ITEMS_FOR_PAGES
            items = [...data[2]].splice(currentPage,ITEMS_FOR_PAGES)

            this.setState({
                info:data[0],
                text:data[1][9],
                list:items,
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }


    nextHandler(){
        const totalElementos = datosFromApi.length
        const nextPage = currentPage + 1
        const firstIndex = nextPage * ITEMS_FOR_PAGES
        if(firstIndex >= totalElementos) return
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list:items
        })
        currentPage = nextPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    prevHandler(){
        const prevPage = currentPage - 1    
        if (prevPage < 0) return
        const firstIndex = prevPage * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list:items
        })
        currentPage = prevPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }

    changeHandler(id){
        const firstIndex = id * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list:items
        })
        currentPage = id
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 0);
    }


    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Content
                        text={this.state.text}

                        list={this.state.list}
                        prevHandler={this.prevHandler}
                        nextHandler={this.nextHandler}
                        currentPage={currentPage}
                        changeHandler={this.changeHandler}
                        pages={pages}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_news')) {
    root.render(
        <News />
    )
}