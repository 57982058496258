import React from 'react'

let vector = [0]

const Header = function header ({info}){
    return (
        <div>
            <header id="header">
                <div className="container flex">
                    <a href={"/"} className="logo flex">
                        <img src="/img/logo.svg" alt="SDI"/>
                    </a>
                    <nav >
                        <a href={"/"}>Inicio</a>
                        <a href={"/nosotros"}>Nosotros</a>
                        <a href={'/soluciones'}>Nuestras Soluciones</a>
                        <a href={"/industrias"}>Industrias</a>
                        <a href={"/noticias"}>Noticias</a>
                        <a href={"/sostenibilidad"}>Sostenibilidad</a>
                        <a href={"/contacto"}>Contáctanos</a>
                    </nav>
                    <div className="social dnone">
                        <a href={info.linkedin} target='_blank' className="flex">
                            <img src="/img/linke.svg" alt="linke-icon"/>
                        </a>
                        <a href={info.youtube} target='_blank'  className="flex">
                            <img src="/img/ytb.svg" alt="ytb-icon"/>
                        </a>
                        <a href="" className="flex">
                            <img src="/img/menuMob/eng.svg" alt="eng-icon"/>
                        </a>
                        <a href="" className="flex">
                            <img src="/img/menuMob/spa.svg" alt="spa-icon"/>
                        </a>
                        <a href={"/acceder"} className="flex">
                            <img src="/img/user.svg" alt="user-icon"/>
                        </a>
                    </div>
                    <div className="actions flex">
                        <a href={"/acceder"} className="flex loginMob">
                            <img src="/img/user.svg" alt="user-icon"/>
                        </a>
                        <img src="/img/menuMob/menu.svg" alt="menu" className="menu" id="nav-toggle"/>
                    </div>
                </div>
            </header>

            <div className="separatorHeader" id="separatorHeader">
            </div>
        </div>
    )
}

export default Header