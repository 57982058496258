setTimeout(function(){
  // GET HEIGHT HEADER FOR AUTO SEPARATOR

  AOS.init({
    duration: 1200,
  })
  
  const header = document.getElementById('header'),
    sepHeader = document.getElementById('separatorHeader')
    if (header) {
      let heightHeader = header.offsetHeight
      sepHeader.style.height = heightHeader.toString() + 'px'
    }


  /*===== MENU SHOW & HIDDEN =====*/
  const navMenu = document.getElementById('nav-menu'),
    toggleMenu = document.getElementById('nav-toggle'),
    closeMenu = document.getElementById('nav-close')
    
    /*SHOW*/
    toggleMenu.addEventListener('click', ()=>{
      navMenu.classList.toggle('show')
    })
    
    /*HIDDEN*/
    closeMenu.addEventListener('click', ()=>{
      navMenu.classList.remove('show')
    })
    
    
    
    // LIST INDUSTRIES
  const openMenuInd = document.getElementById('openMenuInd'),
    menuInd = document.getElementById('menuInd'),
    closeMenuInd = document.getElementById('closeMenuInd')

  if(menuInd){
    openMenuInd.addEventListener('click', ()=>{
      menuInd.classList.toggle('show')
    })
    closeMenuInd.addEventListener('click', ()=>{
      menuInd.classList.remove('show')
    })
  }

  ///////OPEN ACCORDION////////
  // let acc = document.getElementsByClassName('accTittle'),
  //     content = document.getElementsByClassName('accContent')

  // if (acc){
  //   for (i=0; i<acc.length; i++){
  //     acc[i].addEventListener('click', function(){
  //       this.classList.toggle('rotateImg')
  //       let content = this.nextElementSibling
  //       content.classList.toggle('openedContent')
  //     })
  //   }
  // }


  function scrollToTargetAdjusted(e) {
    
    let sections = document.querySelectorAll('.sectionInd')
    
    let links = document.querySelectorAll('.goSectionInd')
    
    let targetIndex = Array.prototype.indexOf.call(links, e.target)
    
    let element = sections[targetIndex]

    let heightHeader = header.offsetHeight
    
    let elementPosition = element.getBoundingClientRect().top + window.scrollY - heightHeader - 150
    
    window.scrollTo({
      top: elementPosition,
      behavior: "smooth"
    });
  }

  let links = document.querySelectorAll('.goSectionInd')
  for (var i = 0; i < links.length; i++) {
      links[i].addEventListener('click', scrollToTargetAdjusted)
  }

},1000)