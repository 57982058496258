import React from 'react'

let vector = [0]

const Banner = function banner ({info_data}){
    return (
        <section className="bannerService">
          <picture>
            <source media="(min-width: 768px)" srcSet={"/"+info_data.img_big}/>
            <img src={"/"+info_data.img} alt="banner-image"/>
          </picture>
        </section>
    )
}

export default Banner