import React from 'react'

let vector = [0]

const Banner = function banner ({text,banner_movil}){
    return (
        <picture className="bannerAb flex">
            <source media="(min-width:1280px)" srcSet={text.img}/>
            <img src={banner_movil.img} alt="banner-about"/>
        </picture>
    )
}

export default Banner