import React from 'react'

let vector = [0]
let validate = 1
let count = 0

const Solutions = function solutions ({list}){
    return (
        <section className="solutionsH">
            <div className="content colflex relative">
                <img src="/img/home/backy.svg" alt="⬅️" className="backY dnone"/>
                <img src="/img/home/nexty.svg" alt="➡️" className="nextY dnone"/>
                <h2 className="relative">Nuestras Soluciones</h2>
                <div className="sliderSolutionsH" id="solutions">
                    {list.map((l)=>{
                        count = count + 1
                        return(
                            <div className={"item colflex solution_"+count} key={l.id}>
                                <a 
                                    href={'/solucion/'+l.slug} 
                                    className="flex">
                                    <img src={l.img} alt="solution-thumb"/>
                                </a>
                                <div className="content colflex">
                                    <div className="tittle flex">
                                        <h4>{l.name}</h4>
                                        <span>0{count}</span>
                                    </div>
                                    <p>{l.text}</p>
                                    <a href={'/solucion/'+l.slug}>
                                        Ver más...
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                        validate = 2
                        setTimeout(function(){
                            $('#solutions').slick({
                                slidesToShow: 1,
                                dots: false,
                                arrows: false,
                                mobileFirst: true,
                                centerMode: true,
                                responsive: [
                                  {
                                    breakpoint: 750,
                                    settings: {
                                      variableWidth: true,
                                    }
                                  },
                                  {
                                    breakpoint: 1250,
                                    settings: {
                                      variableWidth: true,
                                      swipe: false,
                                      dots: false,
                                      arrows: true,
                                      prevArrow: '.backY',
                                      nextArrow: '.nextY'
                                    }
                                  },
                                ]
                            });
                        },100)
                    }
                })}
                <a className="goSolutions" href={'/soluciones'}>Ver todas</a>
            </div>
        </section>
    )
}

export default Solutions