import React from 'react'

let vector = [0]

var vector_pages = [];

const Content = function content ({
        text,list,
        prevHandler,nextHandler,currentPage,changeHandler,pages,
    }){

    let pagination = ""
    let page = ""
    let count_pages = 0
    
    if(parseInt(pages) >= 1){
        let vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <div className="paginator container flex" key={1}>
                    <img onClick={prevHandler} src="/img/news/back.svg" alt="⬅️"/>
                    <div className="numbers flex">
                        {vector_pages.map((v)=>{
                            count_pages = count_pages + 1
                            if(count_pages<=10 && currentPage <= 8){
                                return (
                                    <span
                                        className={currentPage == v?"selected":""}  
                                        onClick={() => changeHandler(v)} 
                                        key={v}
                                    >
                                        {v+1}
                                    </span>
                                )
                            }else if(count_pages >= 9 && currentPage >= 9){
                                return (
                                    <a 
                                        className={currentPage == v?"selected":""}  
                                        onClick={() => changeHandler(v)} 
                                        key={v}
                                    >
                                        {v+1}
                                    </a>
                                )
                            }
                        })}
                    </div>
                    <img onClick={nextHandler} src="/img/news/next.svg" alt="➡️"/>
                </div>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
    }

    return (
        <section className="news">

            <div className="container colflex">
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>

                <div className="newsList grid" id="allProducts">

                    {list.map((l)=>{
                        return(
                            <div className="item" key={l.id}>
                                <a href={'/sostenibilidad/'+l.slug} className="flex">
                                    <img src={l.img} alt="post-thumb"/>
                                </a>
                                <div className="info colflex">
                                    <a href={'/sostenibilidad/'+l.slug}>{l.title}</a>
                                    <span>{l.date}</span>
                                    <a href={'/sostenibilidad/'+l.slug}>Ver más</a>
                                </div>
                            </div>
                        )
                    })}

                </div>

            </div>

            {pagination}

        </section>
    )
}

export default Content