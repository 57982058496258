import React from 'react'

let vector = [0]
import { Fancybox } from "@fancyapps/ui"

const Gallery = function gallery ({list,images}){
    return (
        <div>
            {list.map((l)=>{
                let images_v = images.filter(
                    (img) => img.industries_id === l.id,
                );
                let count = 0
                return(
                    <div className="galleryProperty dnone " key={l.id}>
                        {images_v.map((i)=>{
                            count = count +1 
                            if(count == 1){
                                return(
                                    <img 
                                        src={i.img} 
                                        data-fancybox={"list_"+l.id} 
                                        alt="image-product"
                                        id={"image_first_"+l.id}
                                        key={i.id}
                                    />
                                )
                            }else{
                                return(
                                    <img 
                                        src={i.img} 
                                        data-fancybox={"list_"+l.id} 
                                        alt="image-product" 
                                        key={i.id}
                                    />
                                )
                            }
                        })}
                    </div>
                )
            })}
            {vector.map((v)=>{
                 $(document).ready(function() {
                    Fancybox.bind("[data-fancybox]", {
                        contentClick: "iterateZoom",
                        Images: {
                            Panzoom: {
                                maxScale: 2,
                            },
                        },
                    });
                });
            })}
        </div>
    )
}

export default Gallery