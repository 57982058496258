import React from 'react'

let vector = [0]
let count = 0

const Content = function content ({text,list}){
    return (
        <section className="portfolio">
            <div className="container colflex">
                <div className="tittle colflex">
                    <h2 className="relative">{text.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>
            
                <div className="listPortfolio grid">
                    {list.map((l)=>{
                        count = count + 1
                        return(
                            <div className={"item colflex solution_"+count} data-aos="fade-up" key={l.id}>
                                <a href={'/solucion/'+l.slug} className="thumb flex">
                                    <img src={l.img} alt="item-image"/>
                                </a>
                                <div className="content colflex">
                                    <div className="title flex">
                                        <h4>{l.name}</h4>
                                        <span>0{count}</span>
                                    </div>
                                    <a href={'/solucion/'+l.slug} className="linkMob">Ver más...</a>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </section>
    )
}

export default Content