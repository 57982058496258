import React from 'react'

let vector = [0]

const Banner = function banner ({banner_desktop,banner_tablet,banner_mobile}){
    return (
        <picture className="bannerAb flex">
            <source media="(min-width:1280px)" srcSet={banner_desktop.img}/>
            <source media="(min-width:768px)" srcSet={banner_tablet.img}/>
            <img src={banner_mobile.img} alt="banner-about"/>
        </picture>
    )
}

export default Banner