import React from 'react'

let vector = [0]
let validate = 1

const Banner = function banner ({banner}){
    return (
        <section className="bannerHome relative" >
            <img src="/img/backw.svg" alt="⬅️" className="arrow backBan absolute dnone"/>
            <img src="/img/nextw.svg" alt="➡️" className="arrow nextBan absolute dnone"/>
            <div className="content" id="bannerHome">
                {banner.map((b)=>{
                    return(
                        <a href={b.url} className="flex" key={b.id}>
                            <picture className="flex">
                                <source media="(min-width:768px)" srcSet={b.img}/>
                                <img src={b.img_mobile}/>
                            </picture>
                        </a>
                    )
                })}
            </div>

            {vector.map((v)=>{
                if(banner.length>0 && validate == 1){
                    validate = 2
                    setTimeout(function(){
                        $('#bannerHome').slick({
                            slidesToShow: 1,
                            dots: true,
                            arrows: false,
                            autoplay: true,
                            autoplaySpeed: 5000,
                            mobileFirst: true,
                            responsive: [
                              {
                                breakpoint: 1250,
                                settings: {
                                  dots: false,
                                  arrows: true,
                                  swipe: false,
                                  prevArrow: '.backBan',
                                  nextArrow: '.nextBan'
                                }
                              },
                            ]
                        });
                    },100)
                }
            })}
        </section>
    )
}

export default Banner