import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]

const Contact = function contact ({
        text,services,
        onChange,form,submit,recaptchaRef
    }){
    return (
        <section className="contactH" style={{backgroundImage: 'url("/img/bgform2.jpg")' }}>
            <div className="container colflex">
                <div className="info colflex">
                    <h2 className="relative">{text.title}</h2>
                    <p>{text.subtitle}</p>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LeAu3cgAAAAAIhj6gFouDEtkkynaMvDRUtSJGM1"
                />
                <form className="colflex" id="formContact">
                    <select 
                        name="contact_services_id"
                        onChange={onChange}
                    >
                        <option value="">Selecciona un servicio</option>
                        {services.map((s)=>{
                            return(
                                <option value={s.id} key={s.id}>
                                    {s.name}
                                </option>
                            )
                        })}
                    </select>
                    <input 
                        type="text" 
                        placeholder="Nombre"
                        name="name"
                        value={form.name}
                        onChange={onChange}
                    />
                    <input 
                        type="text" 
                        placeholder="Empresa"
                        name="company"
                        value={form.company}
                        onChange={onChange}
                    />
                    <input 
                        type="text" 
                        placeholder="Teléfono"
                        name="phone"
                        value={form.phone}
                        onChange={onChange}
                    />
                    <input 
                        type="text" 
                        placeholder="Correo"
                        name="mail"
                        value={form.mail}
                        onChange={onChange}
                    />
                    <textarea 
                        rows="6"
                        placeholder="Mensaje..."
                        name="message"
                        value={form.message}
                        onChange={onChange}
                    ></textarea>
                    <div className="policy colflex">
                        <p>Al enviar este mensaje autorizas <a href={'/terminos-y-condiciones'} target='_blank'>el uso y tratamiento de datos.</a></p>
                        <a 
                            className="btnFormContact" 
                            onClick={()=>submit()}
                        >
                            Enviar
                        </a>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact