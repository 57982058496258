import React from 'react'

let vector = [0]
let validate = 1

const Content = function content ({post_info,list}){
    return (
        <section className="contentPost">
            <div className="contentSection colflex">
                <div className="content colflex">
                    <img src={"/"+post_info.img_big} alt="image-post" className="wallpost"/>
                    <h3>{post_info.title}</h3>
                    <div className="date flex">
                        <img src="/img/date.svg" alt="calendar-icon"/>
                        <span>{post_info.date}</span>
                    </div>
                    <div className="textPost">
                        <p dangerouslySetInnerHTML={{__html: post_info.text}}></p>
                    </div>
                </div>
                <aside>
                    <div className="content colflex">
                        <h3>Te pueden interesar:</h3>
                        <div className="interestPost" id="interestPosts">

                            {list.map((l)=>{
                                return(
                                    <div className="item" key={l.id}>
                                        <a href={'/sostenibilidad/'+l.slug} className="flex">
                                            <img src={"/"+l.img} alt="post-thumb"/>
                                        </a>
                                        <div className="info colflex">
                                            <a href={'/sostenibilidad/'+l.slug}>{l.title}</a>
                                            <span>{l.date}</span>
                                            <a href={'/sostenibilidad/'+l.slug}>Ver más</a>
                                        </div>
                                    </div>
                                )
                            })}
                            
                        </div>
                        {vector.map((v)=>{
                            if(list.length > 0 && validate == 1){
                                validate = 2
                                setTimeout(function(){
                                    $('#interestPosts').slick({
                                        slidesToShow: 1,
                                        dots: true,
                                        arrows: false,
                                        autoplay: true,
                                        autoplaySpeed: 5000,
                                        mobileFirst: true,
                                        centerMode: true,
                                        variableWidth: true,
                                        responsive: [
                                          {
                                            breakpoint: 760,
                                            settings: {
                                              slidesToShow: 2,
                                            }
                                          },
                                          {
                                            breakpoint: 1020,
                                            settings: 'unslick'
                                          },
                                        ]
                                    });
                                },100)
                            }
                        })}
                    </div>
                </aside>
            </div>
        </section>
    )
}

export default Content