import React from 'react'

let vector = [0]

const Content = function content ({text}){
    return (
        <section className="about colflex">
            <div className="container colflex">
                <div className="info colflex">
                    <h2 className="relative">{text.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>
                <img src={text.img} alt="about-image" data-aos="fade-up"/>
            </div>
        </section>
    )
}

export default Content