if(window.location.pathname.replace(https,'') == "/"){
    require('./view/index');
}
if(window.location.pathname.replace(https,'') == "/nosotros"){
    require('./view/about');
}
if(window.location.pathname.replace(https,'') == "/contacto"){
    require('./view/contact');
}
if(window.location.pathname.replace(https,'') == "/industrias"){
    require('./view/industries');
}
if(window.location.pathname.replace(https,'') == "/noticias"){
    require('./view/news');
}
if(window.location.pathname.replace(https,'').includes("/noticia/")){
    require('./view/post');
}
if(window.location.pathname.replace(https,'') == "/acceder"){
    require('./view/login');
}
if(window.location.pathname.replace(https,'') == "/archivos"){
    require('./view/files');
}
if(window.location.pathname.replace(https,'') == "/terminos-y-condiciones"){
    require('./view/terms');
}
if(window.location.pathname.replace(https,'') == "/soluciones"){
    require('./view/solutions');
}
if(window.location.pathname.replace(https,'').includes("/solucion/")){
    require('./view/solutionsInfo');
}
if(window.location.pathname.replace(https,'') == "/sostenibilidad"){
    require('./view/sustainability');
}
if(window.location.pathname.replace(https,'').includes("/sostenibilidad/")){
    require('./view/sustainability_post');
}