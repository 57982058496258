import React from 'react'

let vector = [0]
let validate = 1

const News = function news ({text,list}){
    return (
        <section className="newsH">
            <div className="content colflex">
                <h2 className="relative">{text.title}</h2>
                <p>{text.text}</p>
                <div className="sliderNewsH" id="news">
                    {list.map((l)=>{
                        return(
                            <div className="item" key={l.id}>
                                <a href={'/noticia/'+l.slug} className="flex">
                                    <img src={l.img} alt="post-thumb"/>
                                </a>
                                <div className="info colflex">
                                    <a href={'/noticia/'+l.slug}>{l.title}</a>
                                    <span>{l.date}</span>
                                    <a href={'/noticia/'+l.slug}>Ver más</a>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                        validate = 2
                        setTimeout(function(){
                            $('#news').slick({
                                slidesToShow: 1,
                                dots: false,
                                arrows: false,
                                centerMode: true,
                                variableWidth: true,
                                mobileFirst: true,
                                responsive: [
                                  {
                                    breakpoint: 760,
                                    settings: {
                                      slidesToShow: 3,
                                    }
                                  },
                                  {
                                    breakpoint: 1020,
                                    settings: 'unslick'
                                  },
                                ]
                            });
                        },100)
                    }
                })}
                <a className="goNews" href={'/noticias'}>Ver todas</a>
            </div>
        </section>
    )
}

export default News