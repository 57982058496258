import React from 'react'

let vector = [0]
let validate = 1
let count_fade = 0

const Industry = function industry ({text,list}){
    return (
        <section className="industryH">
            <div className="content colflex">
                <h2 className="relative">{text.title}</h2>
                <p>{text.text}</p>
                <div className="sliderIndustryH" id="industry">
                    {list.map((l)=>{
                        count_fade = count_fade + 50
                        return(
                            <div className="item colflex" data-aos="fade-right" data-aos-delay={count_fade} key={l.id}>
                                <img src={l.img} alt="industry-img"/>
                                <span>{l.name}</span>
                            </div>
                        )
                    })}
                </div>
                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                        validate = 2
                        setTimeout(function(){
                            $('#industry').slick({
                                slidesToShow: 1,
                                dots: false,
                                arrows: false,
                                centerMode: true,
                                variableWidth: true,
                                mobileFirst: true,
                                autoplay: true,
                                autoplaySpeed: 5000,
                                responsive: [
                                  {
                                    breakpoint: 760,
                                    settings: {
                                      slidesToShow: 3,
                                    }
                                  },
                                  {
                                    breakpoint: 1020,
                                    settings: 'unslick'
                                  },
                                ]
                            });
                        },100)
                    }
                })}
                <a className="goIndustry" href={'/industrias'}>Saber más</a>
            </div>
        </section>
    )
}

export default Industry