import React from 'react'

let vector = [0]

const Menu = function menu ({info}){
    return (
        <div className="contMob colflex" id="nav-menu">
            <div className="flex close">
                <img src="/img/menuMob/close.svg" alt="close" id="nav-close"/>
            </div>
  
            <div className="content colflex">
                <div className="links grid">
                    <a href={"/"}>Inicio</a>
                    <a href={"/nosotros"}>Nosotros</a>
                    <a href={'/soluciones'}>Nuestras Soluciones</a>
                    <a href={"/industrias"}>Industrias</a>
                    <a href={"/noticias"}>Noticias</a>
                    <a href={"/sostenibilidad"}>Sostenibilidad</a>
                    <a href={"/contacto"}>Contáctanos</a>
                </div>
                <div className="actions colflex">
                    <div className="item flex">
                        <p>Idioma</p>
                        <div className="icons flex">
                            <a href="" className="flex">
                                <img src="/img/menuMob/eng.svg" alt="eng-icon"/>
                            </a>
                            <a href="" className="flex">
                                <img src="/img/menuMob/spa.svg" alt="spa-icon"/>
                            </a>
                        </div>
                    </div>
                    <div className="item flex">
                        <p>Síguenos</p>
                        <div className="icons flex">
                            <a href={info.linkedin} target='_blank'  className="flex">
                                <img src="/img/menuMob/linke.svg" alt="Ig-icon"/>
                            </a>
                            <a href={info.youtube} target='_blank'  className="flex">
                                <img src="/img/menuMob/ytb.svg" alt="Fb-icon"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    )
}

export default Menu