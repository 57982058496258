import React from 'react'

let vector = [0]

const Content_2 = function content_2 ({text,list}){
    return (
        <section className="about2 colflex">
            <div className="container colflex">
                <div className="info colflex">
                    {list.map((l)=>{
                        return(
                            <p><span>{l.title}:</span> {l.text}</p>
                        )
                    })}
                </div>
                <img src={text.img} alt="about-image" data-aos="fade-up"/>
            </div>
        </section>
    )
}

export default Content_2