import React from 'react'

let vector = [0]

const Content = function content ({info_data,list}){
    return (
        <section className="contentService">
            <div className="container colflex">
                <div className="resume colflex">
                    <div className="title flex">
                        <h2>{info_data.name}</h2>
                        <span>0{info_data.id}</span>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: info_data.text}}></p>
                </div>
                <div className="textService colflex">
                    {list.map((l)=>{
                        return(
                            <div className="item colflex" key={l.id}>
                                <div className="text colflex">
                                    <h4>{l.title}</h4>
                                    <p dangerouslySetInnerHTML={{__html: l.text}}></p>
                                </div>
                                <img src={"/"+l.img} alt="item-image"/>
                            </div>
                        )
                    })}
                    
                </div>
            </div>
        </section>
    )
}

export default Content