import React from 'react'

let vector = [0]

const About = function about ({text}){
    return (
        <section className="aboutH">
            <div className="container colflex">
                <img src={text.img} data-aos="fade-up" alt="about-image"/>
                <div className="text colflex">
                    <h2 className="relative">{text.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                    <a href={'/nosotros'}>Saber más</a>
                </div>
            </div>
        </section>
    )
}

export default About