import React from 'react'

let vector = [0]

const Footer = function footer ({info}){
    return (
        <div>
            <a href={"https://api.whatsapp.com/send/?phone=57"+info.whatsapp} target="_blank" className="floatWpp grid flex" data-aos="fade-left">
                <img src="/img/wpp.svg" alt="🌐"/>
            </a>

            <footer>
                <div className="container colflex">
                    <img src="/img/logoy.svg" alt="logo-white" className="logoFoo"/>
                    <div className="item colflex">
                        <h3>contacto</h3>
                        <p>{info.phone_one}</p>
                        <p>{info.phone_two}</p>
                        <p>{info.mail}</p>
                    </div>
                    <div className="item colflex">
                        <h3>dirección</h3>
                        <p>{info.addres_one}</p>
                        <p>{info.address_two}</p>
                        <p>Atlántico - Colombia</p>
                    </div>
                    <div className="item colflex">
                        <h3>síguenos</h3>
                        <div className="social flex">
                            <a href={info.linkedin} target='_blank' className="flex">
                                <img src="/img/menuMob/linke.svg" alt=""/>
                            </a>
                            <a href={info.youtube} target='_blank' className="flex">
                                <img src="/img/menuMob/ytb.svg" alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
                
                <div className="gtnd colflex">
                    <p>
                        SDI Soluciones de Ingeniería. <br/>
                        Todos los derechos <br/>
                        reservados
                    </p>
                    <span>|</span>
                    <div className="mark colflex">
                        <p>Creado por</p>
                        <a href="https://gutand.com/" target='_blank' className="flex">
                            <img src="/img/gtnd.svg" alt="Gutand"/>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer