import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Container from "../components/industries/container";
import Float from "../components/industries/float";
import Gallery from "../components/industries/gallery";

const rootElement = document.getElementById("content_industries");
const root = createRoot(rootElement);

export default class Industries extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'industries',
            info:[],
            text:[],
            list:[],
            images:[],
            error: null,
        }

        this.openGallery = this.openGallery.bind()
    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/industries')
            let data = await res.json()
            this.setState({
                info:data[0],
                text:data[1][8],
                list:data[2],
                images:data[3]
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    openGallery(id){
        document.getElementById("image_first_"+id).click();
    }
    
    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Container
                        text={this.state.text}
                        list={this.state.list}
                        images={this.state.images}
                        openGallery={this.openGallery}
                    />
                    <Float
                        list={this.state.list}
                    />
                    <Gallery
                        list={this.state.list}
                        images={this.state.images}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_industries')) {
    root.render(
        <Industries />
    )
}