import React from 'react'

let vector = [0]

const Float = function float ({list}){
    return (
        <div>
            <div className="openMenuInd colflex" id="openMenuInd">
                <img src="/img/industries/menu.svg" alt="menu"/>
                <p>
                    Listado<span> de
                    industrias</span>
                </p>
            </div>

            <div className="menuInd" id="menuInd">
                <div className="content colflex">
                    <div className="tittle flex">
                        <h4>Listado<br/>de industrias</h4>
                        <img src="/img/menuMob/close.svg" alt="X" id="closeMenuInd"/>
                    </div>
                    {list.map((l)=>{
                        return(
                            <div className="item flex goSectionInd" key={l.id}>
                                <img src={l.img} alt="icon"/>
                                <p>{l.name}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Float