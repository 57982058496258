import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Content from "../components/solutions/content";

const rootElement = document.getElementById("content_solutions");
const root = createRoot(rootElement);

export default class Solutions extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'solutions',
            info:[],
            text:[],
            list:[],
            error: null,
        }

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/solutions')
            let data = await res.json()
            this.setState({
                info:data[0],
                text:data[1][13],
                list:data[2]
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Content
                        text={this.state.text}
                        list={this.state.list}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_solutions')) {
    root.render(
        <Solutions />
    )
}