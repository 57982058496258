import React from 'react'

let vector = [0]

const Content_3 = function content_3 ({text,list}){
    return (
        <section className="about3 colflex">
            <div className="container colflex">
                <div className="info colflex">
                    <h3>Valores corporativos: </h3>
                    <ul>

                        {list.map((l)=>{
                            return(
                                <li><span>{l.title}:</span> {l.text}</li>
                            )
                        })}
                    </ul>
                </div>
                <img src={text.img} alt="about-image" data-aos="fade-up"/>
            </div>
        </section>
    )
}

export default Content_3