import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Content from "../components/sustainability_post/content";

const rootElement = document.getElementById("content_sustainability_post");
const root = createRoot(rootElement);

export default class SustainabilityPost extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'post',
            info:[],
            new:[],
            list:[],
            error: null,
        }

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/sustainability/'+post_id)
            let data = await res.json()
            this.setState({
                info:data[0],
                new:data[1],
                list:data[2],
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Content
                        post_info={this.state.new}
                        list={this.state.list}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_sustainability_post')) {
    root.render(
        <SustainabilityPost />
    )
}