import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Content from "../components/login/content";

const rootElement = document.getElementById("content_login");
const root = createRoot(rootElement);

export default class Login extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'login',
            info:[],
            formLogin:{
                user:'',
                password:'',
                token:'',
            },
            error: null,
        }

        this.handleChangeFormLogin = this.handleChangeFormLogin.bind(this)
        this._recaptchaRefLogin = React.createRef();

        this.submitClick = this.submitClick.bind(this)

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/login')
            let data = await res.json()
            this.setState({
                info:data[0],
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    handleChangeFormLogin(e){
        this.setState({
            formLogin:{
                ...this.state.formLogin,
                [e.target.name]:e.target.value
            }
        })
    }

    formLoginSubmit = async (e) => {
        if(
            this.state.formLogin.user == '' ||
            this.state.formLogin.password == ''
        ){
            swal({
                text: "Debes digitar todos los datos",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        try{
            let configLogin = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.formLogin)
            }
            let resLogin = await fetch(`/api/login`,configLogin)
            let dataLogin = await resLogin.json()
            if(dataLogin == 1){
                this.setState({
                    formLogin:{
                        user:'',
                        password:'',
                        token:'',
                    }
                })
                location.href = '/archivos';
            }else if(dataLogin == 2) {
                swal({
                    text: "Error de recaptcha",
                    icon: "warning",
                    button: "OK!",
                });
            }else if(dataLogin == 3) {
                swal({
                    text: "Usuario o contraseña incorrecta",
                    icon: "warning",
                    button: "OK!",
                });
            }else{
                alert('ocurrio un error');
                setTimeout(function (){
                    location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formLoginSubmit()
    }


    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Content
                        formlogin={this.state.formLogin}
                        onChangeLogin={this.handleChangeFormLogin}
                        recaptchaRefLogin={this._recaptchaRefLogin}
                        onSubmitLogin={this.formLoginSubmit}
                        submitClick={this.submitClick}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_login')) {
    root.render(
        <Login />
    )
}