import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Banner from "../components/solutionsInfo/banner";
import Content from "../components/solutionsInfo/content";

const rootElement = document.getElementById("content_solutions_info");
const root = createRoot(rootElement);

export default class SolutionsInfo extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'solutionsInfo',
            info:[],
            info_data:[],
            list:[],
            error: null,
        }

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/solution/'+solution_id)
            let data = await res.json()
            this.setState({
                info:data[0],
                info_data:data[1],
                list:data[2]
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <Header
                    info={this.state.info}
                />
                <Menu
                    info={this.state.info}
                />
                <main>
                    <Banner
                        info_data={this.state.info_data}
                    />
                    <Content
                        info_data={this.state.info_data}
                        list={this.state.list}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_solutions_info')) {
    root.render(
        <SolutionsInfo />
    )
}