import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]

const Container = function container ({
        info,text,services,
        onChange,form,submit,recaptchaRef
    }){
    return (
        <section className="contact">
            <div className="container colflex">
                <div className="tittle colflex">
                    <h2 className="relative">{text.title}</h2>
                    <p>{text.text}</p>
                </div>
                <div className="info colflex">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LeAu3cgAAAAAIhj6gFouDEtkkynaMvDRUtSJGM1"
                    />
                    <form className="colflex" id="formContact">
                        <h3>Escríbenos</h3>
                        <select 
                            name="contact_services_id"
                            onChange={onChange}
                        >
                            <option value="">Selecciona un servicio</option>
                            {services.map((s)=>{
                                return(
                                    <option value={s.id} key={s.id}>
                                        {s.name}
                                    </option>
                                )
                            })}
                        </select>
                        <input 
                            type="text" 
                            placeholder="Nombre"
                            name="name"
                            value={form.name}
                            onChange={onChange}
                        />
                        <input 
                            type="text" 
                            placeholder="Empresa"
                            name="company"
                            value={form.company}
                            onChange={onChange}
                        />
                        <input 
                            type="text" 
                            placeholder="Teléfono"
                            name="phone"
                            value={form.phone}
                            onChange={onChange}
                        />
                        <input 
                            type="text" 
                            placeholder="Correo"
                            name="mail"
                            value={form.mail}
                            onChange={onChange}
                        />
                        <textarea 
                            rows="6"
                            placeholder="Mensaje..."
                            name="message"
                            value={form.message}
                            onChange={onChange}
                        ></textarea>
                        <div className="policy flex">
                            <p>Al enviar este mensaje autorizo <a href={'/terminos-y-condiciones'} target='_blank'> el uso y tratamiento de datos.</a></p>
                            <a 
                                className="btnFormContacto" 
                                onClick={()=>submit()}
                            >
                                Enviar
                            </a>
                        </div>
                    </form>
                    <div className="call colflex">
                        <h3>Llámanos</h3>
                        <div className="item colflex">
                            <h4>Galapa</h4>
                            <p>{info.phone_one}</p>
                        </div>
                        <div className="item colflex">
                            <h4>Soledad</h4>
                            <p>{info.phone_two}</p>
                        </div>
                        <div className="item follow colflex">
                            <h4>Síguenos</h4>
                            <div className="social flex">
                                <a href={info.linkedin} target="_blank" className="flex">
                                    <img src="/img/linke.svg" alt="linkedin"/>
                                </a>
                                <a href={info.youtube} target="_blank" className="flex">
                                    <img src="/img/ytb.svg" alt="youtube"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <img src={text.img} alt="contact-image" className="contactImage" data-aos="fade-up"/>
                </div>
            </div>
        </section>
    )
}

export default Container