import React from 'react'

let vector = [0]
let validate = 1

const Container = function container ({
        text,list,images,openGallery
    }){
    return (
        <section className="industries">
            <div className="container colflex">

                <div className="tittle colflex">
                    <h2 className="relative">{text.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>

                {list.map((l)=>{
                    let images_v = images.filter(
                        (img) => img.industries_id === l.id,
                    );
                    return(
                        <div className="item colflex sectionInd" data-aos="fade-up" key={l.id}>
                            <h2 className="relative">{l.name}</h2>
                            <div className="gallery relative">
                                <a
                                    onClick={()=>openGallery(l.id)}
                                    className="absolute flex"
                                >
                                    <img src="/img/industries/allicon.svg" alt="📷"/>
                                    Ver todas
                                </a>

                                <div 
                                    className="sliderBig" 
                                    id={"sliderInd"+l.id}
                                >
                                    {images_v.map((i)=>{
                                        return(
                                            <img src={i.img} alt="image-gallery" key={i.id}/>
                                        )
                                    })}
                                </div>

                                <div 
                                    className="asnavSliderBig" 
                                    id={"asnavInd"+l.id}
                                >
                                    {images_v.map((i)=>{
                                        return(
                                            <img src={i.img} alt="image-gallery" key={i.id}/>
                                        )
                                    })}
                                </div>
                                {vector.map((v)=>{
                                    setTimeout(function(){
                                        $('#sliderInd'+l.id).slick({
                                            slidesToShow: 1,
                                            slidesToScroll: 1,
                                            arrows: false,
                                            fade: true,
                                            asNavFor: '#asnavInd1'
                                        });
                                        $('#asnavInd'+l.id).slick({
                                            slidesToShow: 4,
                                            slidesToScroll: 1,
                                            asNavFor: '#sliderInd1',
                                            dots: false,
                                            //centerMode: true,
                                            arrows: false,
                                            focusOnSelect: true,
                                            mobileFirst: true,
                                            responsive: [
                                            {
                                                breakpoint: 1020,
                                                settings: {
                                                slidesToShow: 4,
                                                }
                                            },
                                            ]
                                        });
                                    },100)
                                })}
                            </div>
                            <div className="info colflex">
                                <h2 className="relative">{l.name}</h2>
                                <p dangerouslySetInnerHTML={{__html: l.text}}></p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default Container